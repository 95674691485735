<template>
  <section class="en-m-index-new">
    <ul class="m-new-wrap-skeleton" v-if="data.length <= 0 && loading">
      <li class="m-new-item" v-for="(item, index) in 5" :key="index">
        <div class="m-new-goods">
          <div class="goods-img"></div>
          <div class="goods-title">
              <p></p>
              <p></p>
          </div>
        </div>
      </li>
    </ul>
    
    <ul class="m-new-wrap">
      <li class="m-new-item" v-for="(item, index) in data" :key="index">
        <router-link :to="{path: `/en/goods/detail/${item.id}`, query: {pre: item.pre_info.is_pre == 1 ? 1 : 0}}" class="m-new-goods">
          <span v-if="item.off != 0"><span>{{100 - item.off}}%</span></span>
          <div v-if="item.merchant_id == 1 && isPre != 1 && item.pre_info.is_pre == 0 && elevenShow" class="eleven"></div>
          <img :src="item.image_url" alt="goods">
          <img src="@/assets/goods/like.png" alt="like" class="like" v-if="item.goods_collect" @click.stop.prevent="FavoriteHandle(item.id, index, 2)">
          <img src="@/assets/goods/like_not.png" alt="like" class="like" v-else @click.stop.prevent="FavoriteHandle(item.id, index, 1)">
          <p class="van-multi-ellipsis--l2">
            <span class="m-mooby" v-if="item.merchant_id == 1 && item.pre_info.is_pre == 0">M</span>
            <span class="m-mooby" style="width:55px" v-if="item.pre_info.is_pre == 1">Pre-Sale</span>
            <span v-if="item.goods_info">{{item.goods_info.name}}</span>
          </p>
          <div class="goods-price" v-if="item.pre_info.is_pre == 1 && item.pre_info.pre_min_price != null">
            <span><span>$</span><span>{{item.pre_info.pre_min_price}}</span></span>
          </div>
          <div class="goods-price" v-else>
            <span><span>$</span><span>{{(item.activity_min_price *1).toFixed(0) != 0 ? item.activity_min_price : (item.min_price * (item.off == 0 ? 1 : item.off / 100)).toFixed(2)}}</span></span>
            <span v-if="(item.activity_min_price *1).toFixed(0) != 0 ? true : item.off != 0">${{item.min_price}}</span>
          </div>
        </router-link>
      </li>
      <li class="m-new-item"></li>
    </ul>
  </section>
</template>

<script>
import { favoriteGoods } from '@/api/en/favorite.js'
export default {
  name:'IndexNew',
  props:['data', 'loading', 'isPre'],
  data() {
    return {
      elevenShow: true // 圣诞活动边框显示
    }
  },
  methods: {
    FavoriteHandle(id, index, n) {
      favoriteGoods(id, {status: n}).then(res => {
        if(res) {
          if(this.data[index].goods_collect == null) {
            this.$set(this.data[index], 'goods_collect', 1)
          } else {
            this.$set(this.data[index], 'goods_collect', null)
          }
          this.$notify({ type: 'success', message: res.msg})
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.en-m-index-new {
  padding-left: 1px;
  margin-bottom: 5px;
  overflow-x: auto;
  overflow-y: hidden;
  transition: all 0.5s ease;
  background-color: #fff;
  .m-new-wrap {
    display: flex;
    padding: 15px;
    list-style: none;
    width: auto;
    .m-new-item{
      margin-right: 12px;
      box-shadow: 0px 10px 15px 0px rgba(217, 217, 217, 0.384);
      display: block;
      width: 130px;
    }
    .m-new-item:last-child{
      min-width: 4px;
      background: transparent !important;
      box-shadow: none;
    }
    .m-new-goods {
      display: inline-block;
      width: 130px;
      color: #333;
      position: relative;
      >span{display: block;width:50px;height:24px;position: absolute;top:0;left:0;background-color: #F94B00;text-align: center;line-height: 24px;
      color:#fff;border-top-left-radius: 5px;
        &::after{content: '';display: block;width:0;height:0;border:12px solid #F94B00;border-right-color: transparent;position: absolute;
        top:0;right:-12px;z-index: 0;border-radius: 5px}
        >span{position: relative;z-index: 9;}
      }
      .like{position: absolute;top:110px;left:100px;width:24px;height:24px}
      &>.eleven{
        position: absolute;
        top: 0;
        left: 0;
        width: 130px;
        height: 140px;
        border-radius: 5px;
        background: url('../../assets/index/eleven_en.png') no-repeat center;
        background-size: 100% 100%;
      }
      &>img {
        width: 130px;
        height: 140px;
        border-radius: 5px;
      }
      &>p {
        margin-top: 8px;
        font-size: 12px;
        letter-spacing: 0.31px;
        line-height: 18px;
        padding:0 10px;
        &>span:nth-of-type(2){
          font-size: 12px;
          line-height: 20px;
        }
      }
      .goods-price {
        margin-top: 7px;
        font-size: 12px;
        padding:0 10px 10px 10px;
        &>span:nth-of-type(1){
          color: #ED2A24;
          &>span:nth-of-type(2){
            font-size: 16px;
          }
        }
        &>span:nth-of-type(2){
          margin-left: 5px;
          color: #999999;
          font-size: 10px;
          text-decoration:line-through ;
        }
      }
    }
  }
  .m-new-wrap-skeleton{
    display: flex;
    list-style: none;
    width: auto;

    .m-new-item{
      margin-right: 12px;
      display: block;
      width: 130px;
      .m-new-goods {
        display: inline-block;
        width: 130px;
        color: #333;
        position: relative;
        .goods-img {
          width: 130px;
          height: 140px;
          border-radius:5px 5px 0px 0px;
          background: #f1f1f1;
        }
        .goods-title {
          width: 130px;
          font-size: 12px;
          letter-spacing: 0.31px;
          line-height: 18px;
          color: #333;
          margin:5px 0 10px 0;
          height:40px;
          >p{
              &:nth-of-type(1){width:100%;height:20px;background: #f1f1f1}
              &:nth-of-type(2){width:30%;height:20px;background: #f1f1f1;margin-top:5px}
          }
        }
      }
    }
  }
}
</style>